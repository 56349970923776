<script setup>
import { computed } from 'vue'
import CopyClipboardIcon from '@/components/common/CopyClipboardIcon'

const props = defineProps({
    mode: { type: String, default: 'light' }, // light, dark
    card: { type: Object, default: () => {} }
})
const classes = computed(() => {
    const classes = ['resource-card']

    classes.push(`resource-card_${props.mode}`)

    return classes
})

const link = computed(() => ({ href: props.card.href }))
const linkList = computed(() => props.card?.urlLists ?? [])
const target = computed(() => props.card.target ?? '_blank')
const preparedImage = computed(() => `resources/${props.card.img}`)
const stylingTag = computed(() => {
    switch (props.card.tag) {
        case 'Video':
            return 'resource-blue'
        case 'Datasheet':
            return 'resource-orange'
        case 'Battle Cards':
            return 'resource-green'
        case 'Text Guide':
            return 'resource-purple'
        case 'Logos':
            return 'resource-pink'
        case 'Terms & Conditions':
            return 'resource-yellow'
        default:
            return 'resource-blue'
    }
})
</script>

<script>
export default {
    name: 'ResourceCard'
}
</script>

<template>
    <div :class="classes">
        <div class="resource-card__image-wrap">
            <BaseImage :image="preparedImage" />
            <div v-if="card.products" class="resource-card__products">
                <BaseIcon
                    :i="product"
                    v-for="(product, index) in card.products"
                    :key="index"
                    class="resource-card__product"
                />
            </div>
        </div>

        <div class="resource-card__content">
            <BaseTag
                v-if="card.tag"
                class="resource-card__tag"
                size="sm"
                :styling="stylingTag"
                :text="card.tag"
            />
            <h3 class="resource-card__title">
                <div v-if="linkList.length">
                    <h6>{{ card.title }}</h6>
                    <div v-for="(url, index) in linkList" :key="index">
                        <BaseLink
                            class="resource-card__title--link multi-links"
                            :link="url"
                            target="_blank"
                        >
                            {{ url?.name }}
                        </BaseLink>
                    </div>
                </div>
                <BaseLink
                    v-else
                    class="resource-card__title--link"
                    :link="link"
                    :target="target"
                >
                    {{ card.title }}
                </BaseLink>
            </h3>
            <div v-if="card.password" class="resource-card__pass">
                <span>
                    pass:
                    <b>{{ card.password }}</b>
                </span>
                <CopyClipboardIcon :text="card.password" />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.resource-card {
    background: #ffffff;
    border-radius: 20px;
    overflow: hidden;
    min-height: 314px;
    display: flex;
    flex-direction: column;

    &__image-wrap {
        position: relative;
    }

    .base-image {
        height: 130px;
        width: 100%;

        &:deep(img) {
            width: inherit;
            object-fit: cover;
        }
    }
    &__products {
        position: absolute;
        top: 10px;
        left: 10px;
        right: 10px;
        display: flex;
        gap: 12px;
    }
    &__product.icon {
        flex: 0 1 54px;
        width: 54px;
        height: 61px;
    }
    &__content {
        padding: 20px 20px 30px 20px;
        display: flex;
        flex-direction: column;
        flex: 1;
    }
    &__tag {
        margin-bottom: 12px;
    }
    &__title,
    &__pass {
        font-size: 18px;
        line-height: 22px;
        font-weight: 300;
        letter-spacing: -0.019em;
        color: $default-text-color;
    }
    &__pass {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
    }
    &__title {
        margin-bottom: 16px;
        flex: 1;
        &--link.link {
            display: block;
            color: $default-text-color;
            transition: 0.2s;
            text-decoration: none;
            color: inherit;
            &:hover {
                color: $mbs-color;
            }
            &.multi-links {
                font-size: 12px;
            }
        }
    }
}
</style>
